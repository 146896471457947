import About from "./About";
import Events from "./Events";
import Featured from "./Featured";
import Gallery from "./Gallery";
import Hero from "./Hero";
import Locations from "./Locations";
import Testimonials from "./Testimonials";

const Body = () => {
  return (
    <div className="flex h-100 justify-center">
      <h1 className="text-center">"There comes a time when you have to choose between turning the page and closing the book"</h1>
      {/* <Hero /> */}
      {/* <Events/> */}
      {/* <Locations /> */}
      {/* <Gallery /> */}
      {/* <Testimonials /> */}

      {/* <Featured/> */}
    </div>
  );
};

export default Body;
